<template>
    <b-row>
        <b-col
            cols="12"
            md="8"
        >
            <b-card no-body>
                <b-card-header>
                    <h4 class="card-title">{{i18nT(`Job offer`)}}</h4>
                    <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        right
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item
                            :href="publicUrl"
                            target="_blank"
                        >
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">{{i18nT(`Preview`)}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'create-edit-job-offer', params: { id: offer.Id }}"
                                         v-if="iCan('job-offers', 'write')"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="onCopy(offer.Id)"
                                         v-if="iCan('job-offers', 'write')"
                        >
                            <feather-icon icon="CopyIcon" />
                            <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteOffer"
                                         v-if="iCan('job-offers', 'write')"
                        >
                            <feather-icon
                                icon="TrashIcon"
                                color="red"
                            />
                            <span
                                class="align-middle ml-50"
                                style="color: red"
                            >{{i18nT(`Delete`)}}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-card-header>
                <b-card-body>
                    <b-row class="border-bottom pb-2">
                        <b-col
                            cols="12"
                            md="12"
                        >
                            <h2>{{offerLabel}}</h2>
                        </b-col>
                        <!--
                        <b-col cols="12" md="12">
                            <div class="bg-light text-dark p-2">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">
                                            {{i18nT(`Offer ID`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{offer.Id}}
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">
                                            {{i18nT(`Published`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{offer.CreatedAt | date}}
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">
                                            {{i18nT(`Contract type`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{getNameByContractTypeId(offer.ContractType)}} {{offer.Remote ? '- Remote' : '- No remote'}}
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">
                                            {{i18nT(`Location`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{getNameByCountryId(offer.Country)}} {{offer.City}}
                                        </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">{{i18nT(`Job category`)}}</h6>
                                        <div class="detail-field pb-2">
                                           {{ getNameByCategoryId(offer.Category) }}
                                        </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">{{i18nT(`Department`)}}</h6>
                                        <div class="detail-field pb-2">
                                           {{ getNameByDepartmentId(offer.Department) }}
                                        </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">{{i18nT(`Seniority Level`)}}</h6>
                                        <div class="detail-field pb-2">
                                           {{ getNameSeniorityLevelId(offer.SeniorityLevel) }}
                                        </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">{{i18nT(`Requirements`)}}</h6>
                                        <div class="detail-field pb-2">
                                           {{ offer.Required }}
                                        </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                    <h6 class="section-label">{{i18nT(`Salary`)}}</h6>
                                    <div class="detail-field pb-2">
                                       {{ offer.SalaryFrom }} - {{ offer.SalaryTo }}
                                    </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                    <h6 class="section-label">{{i18nT(`Equity`)}}</h6>
                                    <div class="detail-field pb-2">
                                       {{ offer.EquityFrom }} - {{ offer.EquityTo }}
                                    </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                    <h6 class="section-label">{{i18nT(`Benefits`)}}</h6>
                                    <div class="detail-field pb-2">
                                       {{ offer.Benefits }}
                                    </div>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">
                                            {{i18nT(`Remote work`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{offer.Remote ? 'Yes' : 'No'}}
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6 class="section-label">
                                            {{i18nT(`Visa sponsorship`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{offer.SponsorVisa ? 'Yes' : 'No'}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        -->
                    </b-row>
                    <b-row class="py-2">
                        <b-col
                            cols="12"
                            md="5"
                        >
                            <!-- -->
                            <h6 class="section-label">{{i18nT(`Offer ID`)}}</h6>
                            <div class="detail-field pb-2">
                                {{offer.Id}}
                            </div>

                            <h6 class="section-label">{{i18nT(`Published`)}}</h6>
                            <div class="detail-field pb-2">
                                {{offer.CreatedAt | date}}
                            </div>

                            <h6 class="section-label">{{i18nT(`Location`)}}</h6>
                            <div class="detail-field pb-2">
                                {{ offer.Address ? offer.Address + ', ': '' }}<br/>
                                {{offer.City ? offer.City + ', ' : ''}}{{ getNameByCountryId(offer.Country) }} {{offer.Zip}}
                            </div>

                            <h6 class="section-label">{{i18nT(`Contract type`)}}</h6>
                            <div class="detail-field pb-2">
                                {{getNameByContractTypeId(offer.ContractType)}}
                            </div>

                            <h6 class="section-label">{{i18nT(`Remote work`)}}</h6>
                            <div class="detail-field pb-2">
                                {{getRemoteType(offer.Remote)}}
                            </div>

                            <h6 class="section-label">{{i18nT(`Job category`)}}</h6>
                            <div class="detail-field pb-2">
                                {{ getNameByCategoryId(offer.Category) }}
                            </div>

                            <h6 class="section-label">{{i18nT(`Department`)}}</h6>
                            <div class="detail-field pb-2">
                                {{ getNameByDepartmentId(offer.Department) }}
                            </div>

                            <h6
                                v-if="offerRequiredSkills"
                                class="section-label"
                            >{{i18nT(`Required Skills`)}}</h6>
                            <div class="detail-field pb-2">
                                <b-badge
                                    class="mr-1"
                                    pill
                                    variant="light-primary"
                                    v-for="requireSkill in offerRequiredSkills"
                                    :key="requireSkill"
                                >
                                    {{ requireSkill }}
                                </b-badge>
                            </div>

                            <h6 class="section-label">{{i18nT(`Seniority Level`)}}</h6>
                            <div class="detail-field pb-2">
                                {{ getNameSeniorityLevelId(offer.SeniorityLevel) }}
                            </div>

                            <h6 class="section-label">{{i18nT(`Salary`)}}</h6>
                            <div
                                v-if="!offer.SalaryConfidential"
                                class="detail-field pb-2"
                            >
                                {{ {amount: offer.SalaryFrom, currency: offer.Currency} | currency}} - {{ {amount: offer.SalaryTo, currency: offer.Currency} | currency}} / {{offer.SalaryFrequency}}
                            </div>
                            <div v-else>
                                -
                            </div>

                            <h6
                                v-if="offer.SponsorVisa"
                                class="section-label"
                            >
                                {{ i18nT(`Foreign Applicants`) }}
                            </h6>
                            <div
                                v-if="offer.SponsorVisa"
                                class="detail-field pb-2"
                            >
                                {{ i18nT(`Company is willing to sponsor visa`) }}
                            </div>




                            <h6
                                v-if="offer.EquityFrom"
                                class="section-label"
                            >{{i18nT(`Equity`)}}</h6>
                            <div
                                v-if="offer.EquityFrom"
                                class="detail-field pb-2"
                            >
                                {{ offer.EquityFrom }} - {{ offer.EquityTo }}
                            </div>

                            <h6
                                v-if="offer.Benefits"
                                class="section-label"
                            >{{i18nT(`Benefits`)}}</h6>
                            <div
                                v-if="offer.Benefits"
                                class="detail-field pb-2"
                            >
                                {{ offer.Benefits }}
                            </div>

                        </b-col>
                        <b-col
                            cols="12"
                            md="7"
                        >
                            <h4>{{i18nT(`Job description`)}}</h4>
                            <div class="detail-field pb-2">
                                <p v-html="offer.Description" />
                            </div>

                            <h4
                                v-if="offer.Additional"
                                class="section-label"
                            >{{i18nT(`Required profile`)}}</h4>
                            <div
                                v-if="offer.Additional"
                                class="detail-field pb-2"
                            >
                                <p v-html="offer.Additional" />
                            </div>

                            <h3
                                v-if="offer.Questions"
                                class="section-label"
                            >{{i18nT(`Application questions`)}}</h3>
                            <div
                                v-if="offer.Questions"
                                class="detail-field pb-2"
                            >
                                <ol class="pl-1">
                                    <li v-for="(question, index) in offer.Questions"
                                        class="pb-1"
                                        :key="index">
                                        {{ question.text }}
                                    </li>
                                </ol>

                            </div>

                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
            <b-card no-body>
                <b-card-header>
                    <h4 class="card-title">{{i18nT(`Information`)}}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row class="border-bottom pb-2">
                        <b-col cols="12">
                            <div class="bg-light text-dark p-2 mb-2">
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <h6 class="section-label">
                                            {{i18nT(`Created`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{offer.CreatedAt | date}}
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <h6 class="section-label">
                                            {{i18nT(`Last update`)}}
                                        </h6>
                                        <div class="detail-field pb-2">
                                            {{offer.UpdatedAt | date}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <h6 class="section-label">{{i18nT(`Job offer url`)}}</h6>
                            <div
                                @click="copyUrl"
                                class="mb-1"
                            >
                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="LinkIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        v-model="publicUrl"
                                        disabled
                                    />
                                </b-input-group>
                            </div>
                            <p class="text-muted mb-0">{{i18nT(`This is the unique job offer page link where candidates can apply for this position. Use and share this link on the job boards where you publish your offer.`)}}</p>
                            <b-button
                                variant="primary"
                                class="mt-1 mb-1"
                                :href="publicUrl"
                                target="_blank"
                                :disabled="!offerPublic"
                            >{{i18nT(`Preview`)}}</b-button>
                        </b-col>
                        <b-col cols="12">
                            <h6 class="section-label">{{i18nT(`Statistics`)}}</h6>
                            <div class="detail-field pb-2">
                                <strong>{{ offer.Seen ? offer.Seen : 0 }}</strong> {{i18nT(`Views`)}}
                            </div>
                            <h6 class="section-label">{{ candidatesMatch.length }} {{i18nT(`Candidates match`)}}</h6>
                            <div class="detail-field pb-2">
                                <b-avatar
                                    v-for="match in candidatesMatch"
                                    :key="match.id"
                                    variant="light-primary"
                                    class="mr-1 mb-1"
                                    :text="match.text"
                                    :src="match.avatar"
                                    v-b-tooltip.hover.top="i18nT(match.fullName)"
                                    :to="{
                      name: 'view-application',
                      params: { id: match.id },
                      query: { offerId: offer.Id },
                    }"
                                ></b-avatar>
                            </div>
                            <h6 class="section-label">{{ candidatesApplied.length }} {{i18nT(`Candidates applied`)}}</h6>
                            <div class="detail-field pb-2">
                                <b-avatar
                                    v-for="applied in candidatesApplied"
                                    variant="light-primary"
                                    class="mr-1"
                                    :key="applied.id"
                                    :src="applied.avatar"
                                    :text="applied.text"
                                    v-b-tooltip.hover.top="i18nT(applied.fullName)"
                                    :to="{
                    name: 'view-application',
                    params: { id: applied.id },
                    query: { offerId: offer.Id },
                  }"
                                ></b-avatar>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="pt-1">
                        <b-col
                            cols="12"
                            class="d-flex justify-content-between"
                        >
                            <span>{{i18nT(`Job offer status is`)}} {{offerPublic ? 'active' : 'inactive'}}</span>
                            <b-form-checkbox
                                name="offerPublic"
                                id="offerPublic"
                                switch
                                :checked="offerPublic"
                                v-model="offerPublic"
                                :disabled="!iCan('job-offers', 'write')"
                            />
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BButton,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BCardHeader,
    BFormCheckbox,
    BCardBody,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    VBTooltip
} from 'bootstrap-vue'
// import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
// import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'

// import draggable from "vuedraggable";

export default {
    components: {
        BButton,
        BBadge,
        BAvatar,
        BRow,
        BCol,
        BFormInput,
        BCard,
        BCardHeader,
        BCardBody,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BDropdown,
        BDropdownItem
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            required,
            offer: {},
            offerCategories: [],
            offerContractTypes: [],
            offerSeniorityLevels: [],
            offerDepartments: [],
            offerJobSkills: [],
            offerCountries: [],
            offerCategory: null,
            offerDepartment: [],
            offerContractType: {},
            offerSeniorityLevel: null,
            offerRequiredSkills: [],
            offerId: '',
            offerLabel: '',
            offerDescription: '',
            offerAddress: '',
            offerCity: '',
            offerCountry: '',
            offerSalaryFrom: '',
            offerSalaryTo: '',
            offerEquityFrom: '',
            offerEquityTo: '',
            offerSalaryConfidential: false,
            offerSponsorVisa: false,
            offerRemote: false,
            offerBenefits: '',
            offerAdditional: '',
            offerNeedCoverLetter: false,
            offerNeedPortfolio: false,
            offerNeedYoutube: false,
            offerNeedTwitter: false,
            offerNeedInstagram: false,
            offerNeedBehance: false,
            offerNeedDribble: false,
            offerNeedGithub: false,
            offerPublic: null,
            candidatesMatch: [],
            candidatesApplied: [],
            questionTypes: [],
            question: null,
            questionType: { value: '1' },
            questionLabel: '',
            questionDescription: '',
            answersData: [''],
            linesCnt: 1,
            possibleAnswers: 1,
            otherOption: false,
            steps: 0,
            stepOptions: [2, 3, 5, 10],
            stepStrings: [null, null, null, null, null, null, null, null, null, null],
            firstStep: '',
            lastStep: '',
            grading: 0,
            publicUrl: ''
        }
    },
    watch: {
        file: function(val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        offerPublic: function(val, oldVal) {
            if (oldVal === null) return
            this.$http
                .post(
                    `offers/changeStatus?id=${this.offer.Id}&status=${
                        val ? 'ACTIVE' : 'DEACTIVATED'
                    }`
                )
                .then(resp => {
                    this.$swal({
                        icon: 'success',
                        title: resp.data.message,
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    })
                })
        }
    },
    async created() {
        let self = this

        let cats = this.$http.get(`system/jobCategories`)
        let contractTypes = this.$http.get(`system/contractTypes`)
        let seniorityLvls = this.$http.get(`system/seniorityLevels`)
        let offerDepts = this.$http.get(`system/departments`)
        let jobSkills = this.$http.get(`offers/jobSkills?type=2`)
        let offerCountries = this.$http.get(`system/countries`)

        await Promise.all([
            cats,
            contractTypes,
            seniorityLvls,
            offerDepts,
            jobSkills,
            offerCountries
        ]).then(function(responses) {
            self.offerCategories = dictToSelectArray(responses[0].data.data)
            self.offerContractTypes = dictToSelectArray(responses[1].data.data)
            self.offerSeniorityLevels = dictToSelectArray(responses[2].data.data)
            self.offerDepartments = dictToSelectArray(responses[3].data.data)
            self.offerJobSkills = responses[4].data.data.map(d => ({
                value: d.id + '',
                text: d.label
            }))
            self.offerCountries = dictToSelectArray(responses[5].data.data)

            const id = self.$route.params.id
            if (id !== 'new') {
                self.$http.get(`offers?id=${id}`).then(({ data }) => {
                    const jobOffer = data.data.offer
                    self.offer = data.data.offer
                    for (let key in data.data.candidates_match) {
                        self.candidatesMatch.push({
                            id: data.data.candidates_match[key].Id,
                            text: `${
                                data.data.candidates_match[key].FirstName[0]
                                    ? data.data.candidates_match[key].FirstName[0]
                                    : ''
                            }${
                                data.data.candidates_match[key].LastName[0]
                                    ? data.data.candidates_match[key].LastName[0]
                                    : ''
                            }`,
                            fullName: `${data.data.candidates_match[key].FirstName} ${data.data.candidates_match[key].LastName}`,
                            avatar: `${data.data.candidates_match[key].ImageUrl}`,
                        })
                    }
                    for (let key in data.data.candidates_applied) {
                        self.candidatesApplied.push({
                            id: data.data.candidates_applied[key].Id,
                            text: `${
                                data.data.candidates_applied[key].FirstName[0]
                                    ? data.data.candidates_applied[key].FirstName[0]
                                    : ''
                            }${
                                data.data.candidates_applied[key].LastName[0]
                                    ? data.data.candidates_applied[key].LastName[0]
                                    : ''
                            }`,
                            fullName: `${data.data.candidates_applied[key].FirstName} ${data.data.candidates_applied[key].LastName}`,
                            avatar: `${data.data.candidates_applied[key].ImageUrl}`,
                        })
                    }
                    self.offerPublic =
                        jobOffer.PublicationStatus == 'ACTIVE' ? true : false
                    self.offerId = jobOffer.Id
                    self.offerLabel = jobOffer.Label
                    self.offerAddress = jobOffer.Address
                    self.offerCity = jobOffer.City
                    self.offerDescription = jobOffer.Description
                    self.offerBenefits = jobOffer.Benefits
                    self.offerAdditional = jobOffer.Additional
                    self.offerNeedBehance = jobOffer.NeedBehance ? true : false
                    self.offerNeedCoverLetter = jobOffer.NeedCoverLetter ? true : false
                    self.offerNeedDribble = jobOffer.NeedDribble ? true : false
                    self.offerNeedGithub = jobOffer.NeedGithub ? true : false
                    self.offerNeedPortfolio = jobOffer.NeedPortfolio ? true : false
                    self.offerNeedTwitter = jobOffer.NeedTwiter ? true : false
                    self.offerNeedYoutube = jobOffer.NeedYoutube ? true : false
                    self.offerSalaryConfidential = jobOffer.SalaryConfidential
                        ? true
                        : false
                    self.offerSponsorVisa = jobOffer.SponsorVisa ? true : false
                    self.offerRemote = jobOffer.Remote ? true : false
                    self.offerSalaryFrom = jobOffer.SalaryFrom
                    self.offerSalaryTo = jobOffer.SalaryTo
                    self.offerEquityFrom = jobOffer.EquityFrom
                    self.offerEquityTo = jobOffer.EquityTo
                    self.offerCategory = self.offerCategories.find(
                        c => c.value === jobOffer.Category.toString()
                    )
                    self.offerDepartment = self.offerDepartments.filter(
                        c => c.value === jobOffer.Department.toString()
                    )
                    self.offerContractType = self.offerContractTypes.find(
                        c => c.value === jobOffer.ContractType.toString()
                    )
                    self.offerSeniorityLevel = self.offerSeniorityLevels.find(
                        c => c.value === jobOffer.SeniorityLevel.toString()
                    )
                    self.offerRequiredSkills = Object.values(jobOffer.RequiredSkills)
                    self.offerCountry = self.offerCountries.find(
                        c => c.value === jobOffer.Country
                    )
                    self.publicUrl = `${window.location.protocol}//${
                        window.location.host
                    }/apply/${jobOffer.Label.split(' ').join('-')}_${jobOffer.Id}`
                })
            }
        })

        await this.$http.get(`surveys/questionTypes`).then(({ data }) => {
            const questionTypes = data.data
            for (const property in questionTypes) {
                this.questionTypes.push({
                    value: property,
                    text: questionTypes[property]
                })
            }
        })
    },
    methods: {
        getOfferCategories() {
            this.$http.get(`system/jobCategories`).then(({ data }) => {
                this.offerCategories = dictToSelectArray(data.data)
            })
        },
        getOfferContractTypes() {
            this.$http.get(`system/contractTypes`).then(({ data }) => {
                this.offerContractTypes = dictToSelectArray(data.data)
            })
        },
        getOfferSeniorityLevels() {
            this.$http.get(`system/seniorityLevels`).then(({ data }) => {
                this.offerSeniorityLevels = dictToSelectArray(data.data)
            })
        },
        getOfferDepartments() {
            this.$http.get(`system/departments`).then(({ data }) => {
                this.offerDepartments = dictToSelectArray(data.data)
            })
        },
        getOfferJobSkills() {
            this.$http.get(`offers/jobSkills?type=2`).then(({ data }) => {
                this.offerJobSkills = data.data.map(d => ({
                    value: d.id,
                    text: d.label
                }))
                console.log(this.offerJobSkills)
            })
        },
        getOfferCountries() {
            this.$http.get(`system/countries`).then(({ data }) => {
                this.offerCountries = dictToSelectArray(data.data)
            })
        },
        getNameByCategoryId(categoryId) {
            const category = this.offerCategories.find(cat => cat.value == categoryId)
            return category ? category.text : ''
        },
        getNameByDepartmentId(departmentId) {
            const department = this.offerDepartments.find(
                dep => dep.value == departmentId
            )
            return department ? department.text : ''
        },
        getNameByContractTypeId(contractTypeId) {
            const contractType = this.offerContractTypes.find(
                con => con.value == contractTypeId
            )
            return contractType ? contractType.text : ''
        },
        getRemoteType(remoteType) {
            switch (remoteType)
            {
                case 0:
                    return this.i18nT(`No, no remote work allowed`)
                case 1:
                    return this.i18nT(`Yes, occasionally`)
                case 2:
                    return this.i18nT(`Yes, full remote allowed`)
                default:
                    return ''
            }

        },
        getNameByCountryId(countryId) {
            const country = this.offerCountries.find(con => con.value == countryId)
            return country ? country.text : ''
        },
        getNameSeniorityLevelId(levelId) {
            const level = this.offerSeniorityLevels.find(lev => lev.value == levelId)
            return level ? level.text : ''
        },
        onCopy(offerId) {
            this.$http
                .post(`offers/copyOffer?id=${offerId}`)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        deleteOffer() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`offers?id=${this.offer.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            router.push({ name: 'job-offers' })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        copyUrl() {
            navigator.clipboard.writeText(this.publicUrl)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Url copied`,
                    variant: 'primary'
                }
            })
        }
    }
}
</script>

<style lang="scss">
.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}
</style>
